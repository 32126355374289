import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarComponent } from 'src/app/common/calender/calender.component';
import { extractDatesFromDateString, getDefaultDatesOfYear } from 'src/app/common/calender/calender.utilities';
import { ICalenderEvent } from 'src/app/common/calender/calender.types';

@Component({
  selector: 'app-dashboard-filters',
  templateUrl: './dashboard-filters.component.html',
  styleUrls: ['./dashboard-filters.component.css']
})
export class DashboardFiltersComponent implements OnInit {
  @ViewChild(CalendarComponent) calenderComponent!: CalendarComponent;

  // input values
  formGroup = new FormGroup({
    securityId: new FormControl<string | null>(null, Validators.required),
    startDate: new FormControl('', Validators.required),
    endDate: new FormControl('', Validators.required)
  });

  dateRange!: Date[] | null;

  constructor(private router: Router, public route: ActivatedRoute) {}

  ngOnInit(): void {
    const { securityId, startDate, endDate } = this.route.snapshot.queryParams;
    this.initialiseSecurity(securityId);
    this.initialiseDates(startDate, endDate);
  }

  private initialiseSecurity = (securityId: string) => {
    this.formGroup.patchValue({ securityId });
  };

  private initialiseDates = (startDate: string, endDate: string) => {
    if (startDate && endDate) {
      const { dateFilter } = extractDatesFromDateString(startDate, endDate);
      this.dateRange = dateFilter;
    } else {
      const { dateFilter } = getDefaultDatesOfYear(true);
      this.dateRange = dateFilter;
    }
  };

  get isFormValid() {
    const values = this.formGroup.value;
    return values.securityId && values.startDate && values.endDate;
  }

  get isFormDirty() {
    const values = this.formGroup.value;
    return !(values.securityId || values.startDate || values.endDate);
  }

  onChangeDates = ({ fromDate, toDate }: ICalenderEvent) => {
    this.formGroup.patchValue({
      startDate: fromDate,
      endDate: toDate
    });
  };

  onConfirm() {
    // we can assume the data is valid at this point
    const data = { ...this.formGroup.value };
    this.onUpdateParams(data);
  }

  onReset() {
    this.formGroup.patchValue({ securityId: null });
    const { dateFilter } = getDefaultDatesOfYear(true);
    this.calenderComponent?.onReset(dateFilter);
    this.onUpdateParams({});
  }

  // Fn to update query params
  onUpdateParams = (data: any) => {
    this.router.navigate(['./'], {
      relativeTo: this.route,
      queryParams: data
    });
  };
}
